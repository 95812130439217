import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-restaurant-invoice',
  templateUrl: './restaurant-invoice.component.html',
  styleUrls: ['./restaurant-invoice.component.scss']
})
export class RestaurantInvoiceComponent {

 constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Restaurant invoice'},
        {name: 'description', lang: 'fr', content: 'Devis restaurant'},
        {name: 'description', lang: 'es', content: 'Factura de restaurante'},
        {name: 'keywords', lang: 'en', content: 'Restaurant invoice,restaurant quotation ,Quotation,Invoice'},
        {name: 'keywords', lang: 'fr', content: 'devis restaurant,Facture restaurant,restaurant facture,facture de restaurant,Facture,devis'},
        {name: 'keywords', lang: 'es', content: 'factura de restaurante,facturas restaurantes,factura de restaurante,citas,Estimacion,Cotización,facturas,Factura'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},

      ]);

        this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);

    }


}
