import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LangueService {

  private translateService: TranslateService;

  constructor(private translate: TranslateService) {
  this.translateService=translate;
  }

  setLangueCouranteSelonRoute(route: string){

      //-- Page Catering Software
      if(route==="catering_invoice_software"){
        this.translateService.use('en');
      }
      else if(route==="logiciel_devis_traiteur"){
        this.translateService.use('fr');
      }
      else if(route==="software_para_catering"){
        this.translateService.use('es');
      }

      //-- Page Catering quotation
      else if(route==="quotation_for_food_catering"){
        this.translateService.use('en');
      }
      else if (route==="devis_traiteur"){
        this.translateService.use('fr');
      }
      else if (route==="cotizaciones_de_catering"){
        this.translateService.use('es');
      }

      //-- Page restaurant
       else if(route==="restaurant_catering_software"){
        this.translateService.use('en');
      }
      else if (route==="logiciel_gestion_restauration"){
        this.translateService.use('fr');
      }
      else if (route==="software_para_restaurantes"){
        this.translateService.use('es');
      }

      //-- Page restaurant invoice
       else if(route==="restaurant_invoice"){
        this.translateService.use('en');
      }
      else if (route==="devis_restaurant"){
        this.translateService.use('fr');
      }
      else if (route==="factura_de_restaurante"){
        this.translateService.use('es');
      }

      //-- Page restaurant Pos
       else if(route==="restaurant_pos_system"){
        this.translateService.use('en');
      }
      else if (route==="logiciel_de_caisse"){
        this.translateService.use('fr');
      }
      else if (route==="sistema_pos_para_restaurantes"){
        this.translateService.use('es');
      }

      //-- Page receipt restaurant
       else if(route==="receipt_restaurant"){
        this.translateService.use('en');
      }
      else if (route==="ticket_de_caisse_restaurant"){
        this.translateService.use('fr');
      }
      else if (route==="recibo_de_restaurante"){
        this.translateService.use('es');
      }


      // Page parrainage
      else if(route==="parrainage"){
        this.translateService.use('fr');
      }
      else if (route==="patrocinio"){
        this.translateService.use('es');
      }
      else if (route==="sponsorship_ar"){
        this.translateService.use('ar');
      }
      else if (route==="sponsorship"){
        this.translateService.use('en');
      }

      // Page recrutement
      else if(route==="recrutement"){
        this.translateService.use('fr');
      }
      else if (route==="reclutamiento"){
        this.translateService.use('es');
      }
      else if (route==="recruitment"){
        this.translateService.use('en');
      }

      //-- Page Politique de confidentialité
      else if(route==="privacy_policy"){
        this.translateService.use('en');
      }
      else if (route==="politique_de_confidentialite"){
        this.translateService.use('fr');
      }
      else if (route==="politica_de_privacidad"){
        this.translateService.use('es');
      }

      //-- Page FAQ
      else if (route === "faq") {
        this.translateService.use('en');
      }
      else if (route === "questions_frequentes") {
        this.translateService.use('fr');
      }
      else if (route === "preguntas_frecuentes") {
        this.translateService.use('es');
      }


      else {
      //-- Par defaut langue de la locale
      this.translateService.use('en');
      }


  }

}
