import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-restaurant-pos',
  templateUrl: './restaurant-pos.component.html',
  styleUrls: ['./restaurant-pos.component.scss']
})
export class RestaurantPosComponent  {

   constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Food Mobile is a restaurant pos system'},
        {name: 'description', lang: 'fr', content: 'Food Mobile est un logiciel de caisse'},
        {name: 'description', lang: 'es', content: 'Food Mobile es un sistema pos para restaurantes'},
        {name: 'keywords', lang: 'en', content: 'restaurant pos system'},
        {name: 'keywords', lang: 'fr', content: 'logiciel de caisse,application caisse enregistreuse,logiciel caisse enregistreuse restaurant,logiciel de caisse enregistreuse,logiciel caisse enregistreuse tactile,logiciel caisse restaurant'},
        {name: 'keywords', lang: 'es', content: 'sistema pos para restaurantes,tpv restaurante,facturacion restaurantes'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},

      ]);

        this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);

    }



}
