import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent  {


  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {

    this.meta.addTags([
      {name: 'description', lang: 'en', content: 'Food Mobile faq'},
      {name: 'description', lang: 'fr', content: 'Questions fréquentes Food Mobile'},
      {name: 'description', lang: 'es', content: 'Preguntas frecuentes Food Mobile'},
      {name: 'keywords', lang: 'en', content: 'faq,Food Mobile'},
      {name: 'keywords', lang: 'fr', content: 'Questions fréquentes , Food Mobile'},
      {name: 'keywords', lang: 'es', content: 'Preguntas frecuentes,Food Mobile'},
      {name: 'charset', content: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    ]);

    if (route.snapshot.url[0] && route.snapshot.url[0].path){
      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

  }

}
