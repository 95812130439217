import {BrowserModule, Title} from '@angular/platform-browser';
import {TitleService} from './services/title.service';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {CateringSoftwareComponent} from './catering-software/catering-software.component';
import {SponsorshipComponent} from './sponsorship/sponsorship.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {CateringQuotationComponent} from './catering-quotation/catering-quotation.component';
import {RestaurantComponent} from './restaurant/restaurant.component';
import {RestaurantInvoiceComponent} from './restaurant-invoice/restaurant-invoice.component';
import {RestaurantPosComponent} from './restaurant-pos/restaurant-pos.component';
import {ReceiptRestaurantComponent} from './receipt-restaurant/receipt-restaurant.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {RecrutementComponent} from './recrutement/recrutement.component';
import { FaqComponent } from './faq/faq.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appRoutes: Routes = [
  {
    path: 'catering_invoice_software',
    component: CateringSoftwareComponent,
    data: {title: "Food Mobile | catering invoice software"}
  },
  {
    path: 'logiciel_devis_traiteur',
    component: CateringSoftwareComponent,
    data: {title: "Food Mobile | logiciel devis traiteur"}
  },
  {
    path: 'software_para_catering',
    component: CateringSoftwareComponent,
    data: {title: "Food Mobile | software para catering"}
  },
  {
    path: 'restaurant_catering_software',
    component: RestaurantComponent,
    data: {title: "Food Mobile | restaurant catering software"}
  },
  {
    path: 'logiciel_gestion_restauration',
    component: RestaurantComponent,
    data: {title: "Food Mobile | logiciel gestion restauration"}
  },
  {
    path: 'software_para_restaurantes',
    component: RestaurantComponent,
    data: {title: "Food Mobile | software para restaurantes"}
  },
  {
    path: 'restaurant_pos_system',
    component: RestaurantPosComponent,
    data: {title: "Food Mobile | restaurant pos system"}
  },
  {
    path: 'logiciel_de_caisse',
    component: RestaurantPosComponent,
    data: {title: "Food Mobile | logiciel de caisse"}
  },
  {
    path: 'sistema_pos_para_restaurantes',
    component: RestaurantPosComponent,
    data: {title: "Food Mobile | sistema pos para restaurantes"}
  },
  {
    path: 'quotation_for_food_catering',
    component: CateringQuotationComponent,
    data: {title: "Food Mobile | quotation for food catering"}
  },
  {
    path: 'devis_traiteur',
    component: CateringQuotationComponent,
    data: {title: "Food Mobile | Devis traiteur"}
  },
  {
    path: 'cotizaciones_de_catering',
    component: CateringQuotationComponent,
    data: {title: "Food Mobile | cotizaciones de catering"}
  },
  {
    path: 'restaurant_invoice',
    component: RestaurantInvoiceComponent,
    data: {title: "Food Mobile | restaurant invoice"}
  },
  {
    path: 'devis_restaurant',
    component: RestaurantInvoiceComponent,
    data: {title: "Food Mobile | devis restaurant"}
  },
  {
    path: 'factura_de_restaurante',
    component: RestaurantInvoiceComponent,
    data: {title: "Food Mobile | factura de restaurante"}
  },
  {
    path: 'receipt_restaurant',
    component: ReceiptRestaurantComponent,
    data: {title: "Food Mobile | receipt restaurant"}
  },
  {
    path: 'ticket_de_caisse_restaurant',
    component: ReceiptRestaurantComponent,
    data: {title: "Food Mobile | ticket de caisse restaurant"}
  },
  {
    path: 'recibo_de_restaurante',
    component: ReceiptRestaurantComponent,
    data: {title: "Food Mobile | recibo de restaurante"}
  },
  {
    path: 'sponsorship',
    component: SponsorshipComponent,
    data: {title: "Food Mobile | sponsorship"}
  },
  {
    path: 'parrainage',
    component: SponsorshipComponent,
    data: {title: "Food Mobile | parrainage"}
  }, {
    path: 'patrocinio',
    component: SponsorshipComponent,
    data: {title: "Food Mobile | patrocinio"}
  },
  {
    path: 'recruitment',
    component: RecrutementComponent,
    data: {title: "Food Mobile | recruitment"}
  },
  {
    path: 'recrutement',
    component: RecrutementComponent,
    data: {title: "Food Mobile | recrutement"}
  },
  {
    path: 'reclutamiento',
    component: RecrutementComponent,
    data: {title: "Food Mobile | reclutamiento"}
  },
  {
    path: 'privacy_policy',
    component: PrivacyPolicyComponent,
    data: {title: "Food Mobile | Privacy Policy"}
  },
  {
    path: 'politique_de_confidentialite',
    component: PrivacyPolicyComponent,
    data: {title: "Food Mobile | Politique de confidentialité"}
  }, {
    path: 'politica_de_privacidad',
    component: PrivacyPolicyComponent,
    data: {title: "Food Mobile | Política de privacidad"}
  },
 {
    path: 'faq',
    component: FaqComponent,
    data: {title: "Food Mobile | FAQ"}
  },
  {
    path: 'questions_frequentes',
    component: FaqComponent,
    data: {title: "Food Mobile | Questions fréquentes"}
  }, {
    path: 'preguntas_frecuentes',
    component: FaqComponent,
    data: {title: "Food Mobile | Preguntas frecuentes"}
  },
  {
    path: '',
    component: CateringSoftwareComponent,
    data: {title: "Food Mobile"}
  },
  {
    path: 'page_not_found',
    component: NotFoundComponent,
    data: {title: "Food Mobile | Page not found"}
  },
  {
    path: '**',
    redirectTo: '/page_not_found'
  }
];


@NgModule({
  declarations: [
    AppComponent,
    CateringSoftwareComponent,
    SponsorshipComponent,
    NotFoundComponent,
    CateringQuotationComponent,
    RestaurantComponent,
    RestaurantInvoiceComponent,
    RestaurantPosComponent,
    ReceiptRestaurantComponent,
    PrivacyPolicyComponent,
    RecrutementComponent,
    FaqComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'}),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(titleService: TitleService) {
    titleService.init();
  }
}
