import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-catering-quotation',
  templateUrl: './catering-quotation.component.html',
  styleUrls: ['./catering-quotation.component.scss']
})
export class CateringQuotationComponent  {

   constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Quotation for food catering'},
        {name: 'description', lang: 'fr', content: 'Devis et Facture traiteur'},
        {name: 'description', lang: 'es', content: 'cotizaciones de catering'},
        {name: 'keywords', lang: 'en', content: 'quotation for food catering,quotation for catering services,catering quotes,catering quotation,catering proposal,catering invoice'},
        {name: 'keywords', lang: 'fr', content: 'Devis traiteur,traiteur devis,Facture traiteur'},
        {name: 'keywords', lang: 'es', content: 'cotizaciones de catering,presupuesto de catering,Citas de catering,Factura catering'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},

      ]);

        this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

}
