import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-catering-software',
  templateUrl: './catering-software.component.html',
  styleUrls: ['./catering-software.component.scss']
})
export class CateringSoftwareComponent {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Food Mobile is a catering invoice software'},
        {name: 'description', lang: 'fr', content: 'Food Mobile est un logiciel de devis traiteur'},
        {name: 'description', lang: 'es', content: 'Food Mobile es un software para catering'},
        {name: 'keywords', lang: 'en', content: 'catering invoice software,catering business invoice software,apps for catering business,catering application,catering software,catering apps,sales and catering software,catering business software,catering software for small business,best catering software,catering management software,catering software programs,catering services app,easy catering,Food Mobile'},
        {name: 'keywords', lang: 'fr', content: 'logiciel devis traiteur,logiciel gestion traiteur,logiciel pour traiteur,logiciel traiteur reception,logiciel traiteur'},
        {name: 'keywords', lang: 'es', content: 'software para catering,programas de contabilidad y facturacion,software de facturacion,software facturacion,software para facturacion,software de facturacion,programas de facturacion'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      if (route.snapshot.url[0] && route.snapshot.url[0].path){
          this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
      }
    }

}
