import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-receipt-restaurant',
  templateUrl: './receipt-restaurant.component.html',
  styleUrls: ['./receipt-restaurant.component.scss']
})
export class ReceiptRestaurantComponent {

   constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Receipt restaurant'},
        {name: 'description', lang: 'fr', content: 'Ticket de caisse restaurant'},
        {name: 'description', lang: 'es', content: 'recibo de  Restaurante '},
        {name: 'keywords', lang: 'en', content: 'Receipt restaurant'},
        {name: 'keywords', lang: 'fr', content: 'Ticket de caisse restaurant,Ticket de caisse'},
        {name: 'keywords', lang: 'es', content: 'recibo de  Restaurante,recibo de pago,recibo,Recibo restaurante'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

    this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);

    }

}
