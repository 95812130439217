import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent  {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'Food Mobile is a restaurant catering software'},
        {name: 'description', lang: 'fr', content: 'Food Mobile est un logiciel de gestion pour la restauration'},
        {name: 'description', lang: 'es', content: 'Food Mobile es un software para restaurantes'},
        {name: 'keywords', lang: 'en', content: 'restaurant catering software,restaurant accounting software'},
        {name: 'keywords', lang: 'fr', content: 'logiciel gestion restauration,logiciel de restauration,logiciel pour restaurant,logiciel de restaurant,restauration logiciel'},
        {name: 'keywords', lang: 'es', content: 'software para restaurantes,sistema restaurante,software punto de venta restaurante,sistema pos para restaurantes,programa para bar,software para cafeterias,comandas para restaurante,software de gestión para restaurantes,programa para administrar restaurante,programas para restaurantes,sistema contable para restaurantes,aplicaciones para restaurantes,soft restaurant facturacion,software contable para restaurantes,sistema de comandas para restaurantes,sistema para restaurante'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);

    }


}
